import Swal from 'sweetalert2'
window.Swal = Swal

$(document).on('turbolinks:load ready', function() {
  $('body').on('click', 'a.swal', function() {
    let element = $(this)

    Swal.fire({
      title: this.dataset['swalTitle'] || '',
      text: this.dataset['swalMsg'] || '',
      icon: 'warning',
      iconHtml: '<i class="fas fa-exclamation-circle"></i>',
      showCancelButton: true,
      confirmButtonText: this.dataset['confirmBtnTxt'] || 'Yes, I am sure.',
      cancelButtonText: 'No, cancel.',
      confirmButtonColor: this.dataset['confirmBtnColor'] || '#dc3545',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (this.dataset['swalSource']) {
          $(`#${this.dataset['swalSource']}`).trigger('click');
        }
        else {
          $('#swal-link').attr('href', element.attr('href'));
          let swalLink = document.getElementById('swal-link');
          swalLink.setAttribute('data-method', element.data('method') || 'delete')

          if(element.data('remote')) {
            swalLink.setAttribute('data-remote', element.data('remote'));
            $('#app-spinner').removeClass('d-none');
          }

          swalLink.click();
        }
      }
    });

    return false;
  });

  $('body').on('click', '.cart-count-alert', function() {
    Swal.fire({
      text: $(this).attr('data-error-text'),
      icon: 'error',
      customClass: {
        content: 'f-size-15'
      }
    }).then(function() {
      $('#swal-link').removeClass('cart-count-alert');
      ('#swal-link').removeAttr('data-error-text');
    });
  });
});
