import consumer from "./consumer"

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    var header_user_id = $('#notifications-header').data('user-id')

    if(header_user_id == data.user_id) {
      $('.notifications-dropdown').html(data.notifications_dropdown)
      $('.notification-list').html(data.notification_list)
    }
  }
});
