import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/link';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
import 'tinymce/icons/default';

$(document).on("cocoon:after-insert", function() {
  if($('#content-types').length)
    tinyMce();
});

let toolbar = 'undo redo | formatselect | strikethrough ' +
  'bold italic underline forecolor | alignleft aligncenter ' +
  'alignright alignjustify | bullist numlist outdent indent | ' +
  'removeformat code image link charmap media | ' +
  'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'

let toolbar_for_notifications = 'undo redo | formatselect | strikethrough ' +
  'bold italic underline forecolor | alignleft aligncenter ' +
  'alignright alignjustify | bullist numlist outdent indent | ' +
  'removeformat code image link charmap media| '

window.getStats = function() {
  var wordcount = tinymce.activeEditor.plugins.wordcount;

  return wordcount.body.getWordCount()
}
var poster_image_url;
var filename;

function tinyMce() {
  tinymce.remove();

  if ($('#notification-form').length)
    toolbar = toolbar_for_notifications

  tinymce.init({
    selector: 'textarea.tinymce',
    menubar: false,
    relative_urls: false,
    remove_script_host: false,
    content_css: false,
    file_picker_types: 'media image',
    media_alt_source: false,
    images_upload_url: $('.custom-wrapper').first().data('tinymce-upload-path'),

    setup: function (editor) {
      editor.on('change', function () {
        tinymce.triggerSave();
      });

      editor.on('OpenWindow', function(eventDetails) {
        tinymce.activeEditor.topLevelWindow = eventDetails.dialog;
      });
    },

    file_picker_callback: function (cb, value, meta) {
      if (meta.filetype == 'media') {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', ".mp4,.mpeg,.mov,.webm");

        input.onchange = function (e) {
          var reader = new FileReader();
          var width = 400;
          var height = 200;
          var file = this.files[0];

          filename = upload_media(this, 'media', meta)
          reader.onload = function(event) {
            event.preventDefault();

            document.getElementsByClassName('tox-textfield')[1].value = width;
            document.getElementsByClassName('tox-textfield')[2].value = height;
          }
          var save_btn = document.querySelector('[title="Save"]');
          var close_btn = document.querySelector('[title="Close"]');

          save_btn.onclick = function(event) {
            event.preventDefault();
            event.stopPropagation();
            tinymce.activeEditor.execCommand('mceInsertContent', false, `<iframe src=${filename} width=${width} height=${height} data-poster=${poster_image_url ? poster_image_url : ''}></iframe>`)
            poster_image_url = '';
            filename = '';
            close_btn.click();
          };

          reader.readAsDataURL(file);
          return
        };

        input.click();
      }

      if (meta.filetype == 'image') {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function (e) {
          upload_media(this, 'image', meta)
          return
        };

        input.click();
      }
    },
    height: 300,
    plugins: [
      'lists link image',
      'code wordcount charmap media table'
    ],
    table_sizing_mode: 'fixed',
    skin: false,
    toolbar1: toolbar,
  });

  function unblockWindow() {
    tinymce.activeEditor.topLevelWindow.unblock();
  }

  function blockWindow() {
    tinymce.activeEditor.topLevelWindow.block("Uploading file...");
  }

  function displayError(error) {
    tinymce.activeEditor.windowManager.alert(error, function(){});
  }

  function successCallback(response, type) {
    unblockWindow()
    var error = response.error;

    if(error) {
      displayError(error)
      return ''
    }
    var file_path = response.location
    if(type=== 'image')
      poster_image_url = file_path
    else
      filename = file_path;

    document.getElementsByClassName('tox-textfield')[0].value = file_path;
  }

  function upload_media(element, type, meta) {
    blockWindow();

    var file = element.files[0];
    var form = new FormData();
    var files = file;

    form.append('filetype', meta.filetype);
    form.append("file", files);
    form.append("type", type);

    $.ajax({
      url: $('.custom-wrapper').first().data('tinymce-upload-path'),
      type: "post",
      data: form,
      contentType: false,
      processData: false,
      async: true,
      success: function(response) {
        successCallback(response, type)
      },
    });
  }
}

export { tinyMce };
