import Sortable from 'sortablejs';

$(document).on('turbolinks:load', function() {
  if($('#content-types-block').length) {
    var el = document.getElementById('content-types-block');

    new Sortable(el, {
      onEnd: function () {
        $("#content-types-block .nested-fields").each(function(index, content_type) {
          content_type.querySelector('.position-field').value = index + 1
        });
      },
    });
  }

  if($('#items-list').length) {
    var el = document.getElementById('items-list');

    Sortable.create(el, {
      store: {
        set: function(sortable) {
          $.ajax({
            type: "PATCH",
            url: el.dataset.url,
            data: {
              item_ids: sortable.toArray()
            }
          });
        }
      }
    });
  }
});
