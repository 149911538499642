$(document).on('turbolinks:load ready', function() {


  $('body').on('focusout', 'input#expiry-field', function() {
    set_expiry_date();
  });

  $('body').on('focusout', 'input.custom-datetime', function() {
    set_expiry_datetime();
  });

  $('body').on('focusout', 'input.custom-datetime-expiry', function() {
    set_expiry_datetime_expiry();
  });

  $( "#signature-type" ).change(function() {
    var iframe = document.querySelector("iframe");
    var headerImage = iframe.contentWindow.document.getElementsByTagName("img")[0]
    var logo = "https://ci3.googleusercontent.com/proxy/wn11PdSq8DkXMmZd8qTw8uSB15q3V8aih5SladQHJZ2EzX8VPrnaqlj0VYFznn5qhV0jSreV9atlutQmu1giJc5BkMhd=s0-d-e1-ft#http://brentmark.square63.net/images/mail-logo.png"
    var curator_logo = "https://ci3.googleusercontent.com/proxy/w9CgI50BgSiAWmxuOUNelwwOHuEEJFyCx1QMnC0CKZ3bIms1vDQQ5r9mHEowL193Vz39W_Xy2tBn3yUkGYdDBcRDa_Lb1Sra3j2QRLw8IlRynOhW1fDkjKK2IuRjLtYw15T8XX2FkEmJNA6PU3HswFT5v5lsz7tJEkMY6836DlCvblrKgL77rmQ4Lg=s0-d-e1-ft#https://brentmark-portal-tinymce-images.s3.amazonaws.com/staging/uploads/CuratoronTBHeader_2021-04-07_06_57_17_-0400.png"
    if ($(this).val() == "curator") {
      headerImage.src = curator_logo
      headerImage.setAttribute('data-mce-src', curator_logo)
    }
    else {
      headerImage.src = logo
      headerImage.setAttribute('data-mce-src', logo)
    }
  });

  if($('#phone-field').length > 0)
    mask_field('phone-field', "(999) 999-9999")

  $('#sidebarCollapse').on('click', function () {
    $('#sidebar').toggleClass('active');
  });

  measure_password();
  handle_app_type();
  parsley_validate_password();

  $('#product_product_type').on('change', function() {
    handle_app_type();
  });

  $(document).on("cocoon:after-insert", function() {
    Parsley.$element.find('.parsley-form').parsley().isValid();
    handle_app_type();
    check_to_hide_or_show_remove_link();
  });

  $('.cart-hover').hover(function () {
    $('.current-cart-hover-view').toggle();
  })

  set_default_product_view();

  $('.grid-view').on('click', function () {
    localStorage.setItem('product-view', 'grid');
    update_product_view();
  });

  $('.list-view').on('click', function () {
    localStorage.setItem('product-view', 'list');
    update_product_view();
  });

  $(window).scroll(adjust_header);

  $('.top-navi-btn').on('click', function () {
    $("HTML, BODY").animate({
      scrollTop: 0
    }, 700);
  });

  if($('.credit-card-form').length > 0)
    validate_credit_card_form();

  $( "#checkout-form" ).on( "keypress", ".credit-card-form", function() {
    validate_credit_card_form();
  });

  $( "#client-wrapper" ).on( "change", "[id^=line_item_quantity]", function() {
    index = $(this).data('quantity')
    form = document.querySelector(`#quantity-${index}`);
    Rails.fire(form, 'submit');
    $('#app-spinner').removeClass('d-none');
  });

  $( "#checkout-form" ).on( "focus", "#phone-field", function() {
    mask_field('phone-field', "(999) 999-9999");
  });

  set_active_category_filter();

  $('.category-filter li a').on('click', function(event) {
    localStorage.setItem('applied-category', event.target.className)
  });

  $('form#edit-profile-form, form.bpanel-user-form').on('submit', function() {
    if(Parsley.$element.find($(this)).parsley().isValid())
      $('#app-spinner').removeClass('d-none');
  });

  $('#collapseTwo').on('click', '.checkout-form-button', function(event) {
    $('#user_state').addClass('show-hidden')
    $('#user_state').show();
  })


  $(document).on('click', '.portal-policy', function() {
    $('#app-spinner').removeClass('d-none');
  });

  $(".alert:not(.alert-info)").delay($('#notification-delay').data('delayAmount')).slideUp(200);

  $("#checkout-form").on('submit', 'form#customer-info-form, form#billing-form, form#coupon-code-form', function() {
    $('#app-spinner').removeClass('d-none');
  });
  $("#checkout-form").on('click', '#proceed-checkout-button', function() {
    $('#app-spinner').removeClass('d-none');
  });

  $('.toggle-wrapper').on('change', '.single-subscription-toggle', function() {
    $.ajax({
      url: $(this).data('requestPath'),
      dataType: 'script'
    });
  });

  $('#my-dropdown').on('click', function() {
    window.location.replace($(this).data('redirectPath'))
  });

  $('.chosen').chosen();
  $('.payment-method-chosen').chosen({
    width: '308px',
    placeholder_text: 'Select Payment Method'
  });

  $('.coupon-products-chosen').chosen({
    placeholder_text: 'Select Some Products'
  });

  $('.chosen-toggle').each(function(index) {
    $(this).on('click', function(e) {
      e.preventDefault();

      $(this).parent().find('option').prop('selected', $(this).hasClass('select')).parent().trigger('chosen:updated');
    });
  });

  $(document).on('click', '.submit-filter-form', function () {
    term = $('#term').val();

    form = $('#filter-section-form')
    document.getElementById("hidden-term").value = term;
    prev_url = form.attr('action')
    prev_method = form.attr('method')
    form.attr('action', $(this).data('url'));
    form.attr('method', 'get');
    if($(this).data('ajax') == true)
      $.ajax({
        type: "GET",
        url: $(this).data('url'),
        data: form.serialize()
      });
    else
      form.submit();
    form.attr('action', prev_url);
    form.attr('method', prev_method);
  });

  $('#admin-wrapper').on('click', '#send-bulk-invitation-btn', function () {
    $(this).hide()
  })

  $('.export-csv').on('click', function () {
    form = null
    if($('.bulk-export-checkbox:checked').length >= 1)
    {
      form = $('#bulk-operations-form')
    }
    else
    {
      form = $('#search-form')
    }

    prev_url = form.attr('action')
    prev_method = form.attr('method')
    form.attr('action', $(this).data('url'));
    form.attr('method', 'get');
    form.submit();
    form.attr('action', prev_url);
    form.attr('method', prev_method);
  });

  $('.search-filters').on('change', function () {
    $('.clear-filter').show();
    $(this).closest('form').submit();
    clear_filter_option();
  });

  $('.clear-filter').on('click', function () {
    $('.search-filters').value = '';
    $(this).show();
  });

  clear_filter_option();

  $('.search-text-field').on('change', function () {
    $('.clear-search').show();
    $(this).closest('form').submit();
    clear_search_fields();
  });

  $('.clear-search').on('click', function () {
    $('.search-text-field').value = '';
    $(this).show();
  });

  clear_search_fields();

  $(document).on('cocoon:after-remove', function() {
    check_to_hide_or_show_remove_link();
  });

  check_to_hide_or_show_remove_link();

  check_to_add_or_remove_active_cart();

  $(document).on('mouseover', '.dropdown', function() {
    $(this).children('.dropdown-menu').addClass('show', 800);
  })

  $(document).on('mouseout', '.dropdown', function() {
    $(this).children('.dropdown-menu').removeClass('show', 800);
  });

  $(document).on('change', '.image-label', function() {
    $(this).siblings('label.custom-file-label').text($(this)[0].files[0].name)
  });

  $(document).on('change', '#product_image', function() {
    readURL(this);
  })

  if($('#cc-cvc').length > 0)
    mask_field('cc-cvc', "999");

  $(document).on('input', 'input#cc-num', function() {
    display_card_name($(this));
  });

  $(document).on('click', '#user-update-dummy-btn', function(event) {
    event.preventDefault();
    $('#user-update-btn').trigger('click');
  });

  $(document).on('click', '#swal-link', function() {
    if($(this).data().remote)
      $('#app-spinner').removeClass('d-none');
  });

  $('#nav-cart-sign').addClass('d-none');

  if(document.querySelectorAll('.number-field').length > 0)
    Inputmask({ 'alias': 'decimal', 'groupSeparator': ',', 'digits': 2, 'rightAlign': false, 'placeholder': '0.00'}).mask(document.querySelectorAll('.number-field'));

  $('.current-cart-view').on('click', '#checkout-continue-btn', function(event) {
    user_card_form = document.getElementById('checkout-credit-card-form');
    submit_card_form = document.getElementById('checkout-continue-btn').dataset['submitCardForm'];
    if(submit_card_form == true || submit_card_form === 'true' ) {
      $('#create-card-btn').click();
      event.preventDefault();
    }
  });

  $('body').on('focusin', 'input#cc-cvc', function() {
    display_cvc_digits($('#cc-num'));
  });

  if($('#user-role-field').length > 0) {
    toggle_bpanel_payment_section();
  }

  $('#client-wrapper').on('click', '.accept-portal-policy', function() {
    checkbox_element = $('#portal-policy-checkbox')
    checkbox_element.attr('checked', !checkbox_element.attr('checked'));
  });

  $('#client-wrapper, #admin-wrapper').on('click', '.payment-method-radio-btn', function() {
    if($(this).val() != 'credit_card') {
      $('#credit-card-section').addClass('d-none');
      $('#cc-num').removeAttr('required')
      $('#cc-expiry').removeAttr('required')
      $('#cc-cvc').removeAttr('required')

      $('#checkout-continue-btn').attr('data-submit-card-form', false)
    }
    else {
      if($(this).data('cardExits') == false) {
        $('#credit-card-section').removeClass('d-none');
        $('#cc-num').attr('required', true)
        $('#cc-expiry').attr('required', true)
        $('#cc-cvc').attr('required', true)
      }
      $('#checkout-continue-btn').attr('data-submit-card-form', $('#checkout-continue-btn').data('isNewCard'))
    }

    $('#checkout-payment-method').val($(this).val())

    if($('#other-payment-method-field').length) {
      $('#other-payment-method-field').toggleClass('d-none', $(this).val() != 'other')

      if($(this).val() != 'other')
        $('#other-payment-method-field textarea').text('')
    }
  });

  $(document).on('change', '.select-all-subscriptions', function(e) {
    var table= $(e.target).closest('.bootstrap-table');
    $('td input:checkbox', table).prop('checked', this.checked);
  });

  $('#user-subscriptions-section').on('click', '.bulk-actions-perform', function(e) {
    var form_action = $(this).parent('.bulk-operation-section').find('.role-filter').val()

    if(form_action) {
      e.preventDefault();

      var form = $("#" + $(this).data('subscriptionsForm'))
      form.attr('action', form_action)

      if(form_action == $(this).data('renew-url')) {
        $.ajax({
          url : form_action,
          data: form.serialize(),
          type: 'post'
        });
      } else {
        form.submit();
      }
    }
  });

  $(document).on('ajax:beforeSend', '.remote-true', function() {
    $('#app-spinner').removeClass('d-none');
  });

  $(document).on('change', '.select-all-subscriptions, .subscription-checkbox', function(e) {
    var grid = $(e.target).closest('.subscription-grid');

    if(grid.find('.subscription-checkbox:checked').length >= 1)
    {
      grid.find('.bulk-actions-perform').removeAttr('disabled')
    }
    else
    {
      grid.find('.bulk-actions-perform').attr('disabled', true)
    }
  });

  $('#client-wrapper').on('click', '.install-app', function() {
    $(this).remove();
  })

  $('#product_acronym').on('change', function() {
    $(this).val($(this).val().split(' ').join(''))
  })
  
  var dateToday = new Date().setHours(0,0,0,0);
  $('#datetimepicker1, #datetimepicker2').datetimepicker({
    format: 'MM-DD-YYYY LT',
    autoclose: true,
    minDate: dateToday
  })

  $(document).on('change', '.renew-count', function(event) {
    renew_total = $('.renew-total')
    count = $(this).val();
    per_renew_cost = renew_total.data('renew-price')
    renew_total_cost = per_renew_cost * count;
    renew_total.text(" ")

    if(count > 0)
      renew_total.text("Renewal Cost: $" + (Math.round(renew_total_cost * 100) / 100).toFixed(2));
  })

  $(document).on('change', '#zip-code', function() {
    value = $(this).val()
    if(value.length == 5)
      return

    used = value.split('-').join('')

    len = used.length
    new_value = ''

    if(len > 5) {
      new_value = [used.slice(0, 5), '-', used.slice(5, len)].join('')
      $(this).val(new_value)
    }
  });

  $('.desktop_app, .publication').on('click', function (event) {
    product_slug = event.target.dataset.product;
    elem = $(event.target).next('a' + '.' + product_slug)[0];

    if (elem.dataset.type == 'desktop_app') {
      var productDownloadURL = '/dashboard/download_update/' + elem.dataset.subscripiton;
    }
    else if (elem.dataset.type == 'publication') {
      var productDownloadURL = '/dashboard/download_publication/' + product_slug;
    }

    elem.setAttribute('href', productDownloadURL);
    elem.click();

    setTimeout(function() {
      elem.setAttribute('href', '');
    }, 150);

  });

  $('.productUpdatePath').on('click', function (event) {
    product_slug = event.target.dataset.product;
    elem = $(event.target).next('a' + '.' + product_slug)[0];

    var productUpdateURL = '/dashboard/product_updates/' + elem.dataset.subscripiton;

    window.location.href = productUpdateURL;
  });

  $('.admin-content').on('click', '.bulk-expiry-update', function(event) {
    if ($('#expiry-field').val())
    $('.bulk-expiry-update').find('input[name=value]').val($('#expiry-field').val());
    else
      event.preventDefault();
  })

  toggle_notification_products();
  toggle_notification_email();
  toggle_alert_products();

  $(document).on('click', '#notification_notify_all_users', function() {
    toggle_notification_products();
  });

  $(document).on('click', '#notification_send_email', function() {
    toggle_notification_email();
  });

  $(document).on('change', '#notification_signature_type', function() {
    toggle_notification_signatures();
  });

  $('#unsunbscribe-emails-form').on('click', function(event) {
    $('.child-sub-toggle-form').each(function(el) {
      el_id = $(this).attr('id');
      var checked =  $(`#${el_id}`)[0].checked;
      $(`#${el_id}`).prop('checked', !checked);
    });
  })

  $(document).on('change', '#unsubcribe-all', function() {
    var checked = $('#unsubcribe-all').is(":checked");
    $('.child-sub-toggle').each(function(el) {
      el_id = $(this).attr('id');
      $(`#${el_id}`).prop('checked', !checked);
    });
  });

  $(document).on('change', '.child-sub-toggle', function() {
    var checked = this.checked;
    if (checked)
      $('#unsubcribe-all').prop('checked', !checked);
  });

  $(document).on('change', '#unsubcribe-all-form', function() {
    var checked = $('#unsubcribe-all-form').is(":checked");
    $('.child-sub-toggle-form').each(function(el) {
      el_id = $(this).attr('id');
      $(`#${el_id}`).prop('checked', checked);
    });
  });

  $(document).on('change', '.child-sub-toggle-form', function() {
    var checked = this.checked;
    if (!checked)
      $('#unsubcribe-all-form').prop('checked', checked);
  });

  $(document).on('click', '.notification-icon', function(event) {
    event.preventDefault();
    $.ajax({
      url : $(this).data('url'),
    });
  })

  $(document).on('click', '#alert_general', function() {
    toggle_alert_products();
  });

  $('.dropdown-toggle-button').on('click', function() {
    $('.notifications-dropdown-items .dropdown-menu').toggle('show')
  })

  $('.custom-wrapper').on('click', '.notifications-close-btn', function() {
    $('.notifications-dropdown-items .dropdown-menu').toggle('show')
  })

  $('#help-btn').on('click', function(event) {
    event.preventDefault();
  });

  $('.help-btn').on('click', function() {
    $('#help-product-title').text($(this).data('productTitle'));
  });

  $('.invoice-link-icon').on('click', function() {
    $('#link-popup-spinner').removeClass('d-none')
    $('#invoice-link-modal-content').addClass('d-none')
  })

  $('#client-wrapper, #admin-wrapper').on('click', '.checkbox-toggle', function() {
    $.ajax({
      type: $(this).data('method'),
      url: $(this).data('requestPath')
    });
  });
});

function toggle_notification_products() {
  if ($('#notification_notify_all_users').is(":checked")) {
    $('#notification-products').hide();
    $('#singature-type').show();
  }
  else {
    $('#notification-products').show();
    $('#singature-type').hide();
  }
}

function toggle_notification_signatures() {
  $('#notification_signature_id').empty();
  var list = $("#notification_signature_id");

  if ($('#notification_signature_type').is(":checked"))
    var items = $('.brentmark-signature-dropdown')[0].options;
  else
    var items = $('.curator-signature-dropdown')[0].options;

  $.each(items, function(index, item) {
    list.append(new Option(item.innerHTML, item.value));
  });
}

function toggle_notification_email () {
  var bool = ($('#notification_send_email').is(":checked"));
  if ($("#notification_signature_id")[0])
    $("#notification_signature_id")[0].required = bool;

  if (bool)
    $('#signature-section').show();
  else
    $('#signature-section').hide();
}

function toggle_alert_products() {
  if ($('#alert_general').is(":checked")) {
    $('#alert-products').hide();
    $('#alert-products-select').removeAttr('required')
  } else {
    $('#alert-products').show();
    $('#alert-products-select').attr('required', 'required')
  }
}

function toggle_bpanel_payment_section() {
  $('.admin-content').on('change', '#user-role-field', function() {
    if($(this).val() == 'client') {
      $('.bpanel-card-section').removeClass('d-none');
    } else {
      $('.bpanel-card-section').addClass('d-none');
    }
  });
}

function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function(e) {
      $('#product-image-preview').attr('src', e.target.result);
    }

    reader.readAsDataURL(input.files[0]);
  }
}

function display_card_name(selector) {
  payform = $.payform

  card_type = payform.parseCardType(selector.val());
  icon_src = $(`#${card_type}`).data('iconSrc');
  $('#card-type-image').attr('src', icon_src);
  unmask_cvc_digits();
}

function display_cvc_digits(selector) {
  payform = $.payform
  card_type = payform.parseCardType(selector.val());

  if (card_type === 'amex') {
    $('#cvc-digits').text('4 digits are required.')
    mask_field('cc-cvc', "9999");
  }
  else if(card_type === null) {
    unmask_cvc_digits();
  }
  else {
    $('#cvc-digits').text('3 digits are required.')
    mask_field('cc-cvc', "999");
  }
}

function mask_field(selector_id, format) {
  inputMask = new Inputmask(format);
  selector = document.getElementById(selector_id)

  inputMask.mask(selector);
}

function parsley_validate_password() {
  window.Parsley.addValidator('uppercase', {
    requirementType: 'number',
    validateString: function(value, requirement) {
      var uppercases = value.match(/[A-Z]/g) || [];
      return uppercases.length >= requirement;
    },
    messages: {
      en: 'Password must contain at least (%s) uppercase letter.'
    }
  });

  window.Parsley.addValidator('lowercase', {
    requirementType: 'number',
    validateString: function(value, requirement) {
      var lowecases = value.match(/[a-z]/g) || [];
      return lowecases.length >= requirement;
    },
    messages: {
      en: 'Password must contain at least (%s) lowercase letter.'
    }
  });

  window.Parsley.addValidator('number', {
    requirementType: 'number',
    validateString: function(value, requirement) {
      var numbers = value.match(/[0-9]/g) || [];
      return numbers.length >= requirement;
    },
    messages: {
      en: 'Password must contain at least (%s) number.'
    }
  });

  window.Parsley.addValidator('minlength', {
    requirementType: 'number',
    validateString: function(value, requirement) {
      var numbers = value.match(/^.{8,}$/g) || [];
      return numbers.length >= requirement;
    },
    messages: {
      en: 'Password must be at least 8 characters long.'
    }
  });

  window.Parsley.addValidator('special', {
    requirementType: 'number',
    validateString: function(value, requirement) {
      var specials = value.match(/[^a-zA-Z0-9]/g) || [];
      return specials.length >= requirement;
    },
    messages: {
      en: 'Password must contain at least (%s) special characters.'
    }
  });
}

function measure_password() {
  password_selector = $('#measure_password')

  if(password_selector.length > 0) {
    password_selector.password({
      shortPass: 'The password is too short',
      badPass: 'Weak Strength, try combining letters & numbers',
      goodPass: 'Medium Strength, try using special characters',
      strongPass: 'Strong password',
      enterPass: 'Type your password',
      showText: true,
      animate: true,
      animateSpeed: 'slow',
      minimumLength: password_selector.data('passwordMinLength')
    });
  }
}

function handle_app_type() {
  selector = $('#product_product_type');
  product_type = selector.find(':selected').text();

  versions_section = $('#product-versions');
  site_link = $('.site-link')
  url_element = $('.site-link input')
  on_boarding_price_element = $('#product-on-borading-price')
  publication_section = $('#publication-file')
  on_boarding_expiry = $('#product_on_boarding_expiry')
  renewal_expiry = $('#product_renewal_expiry')
  if(product_type === 'Web App')
  {
    versions_section.hide();
    site_link.show();
    url_element.attr('required', 'required')
    url_element.attr('data-parsley-urlstrict', true);
    $('.nested-fields input').removeAttr('required')
    set_web_desktop_app_product_input_fields();
    product_toggle_fields('show')
    show_expiry_feilds();
  }
  else if(product_type === 'Desktop App')
  {
    site_link.hide();
    versions_section.show();
    url_element.removeAttr('required')
    url_element.removeAttr('data-parsley-urlstrict');
    $('.nested-fields .versions input').attr('required', 'required')
    $('.nested-fields .attachments input[type=text]').attr('required', 'required')
    $('.nested-fields .attachments input[type=file]').each(function () {
      if($(this).next().val() == '')
        $(this).attr('required', $(this).data('required'))
      else {
        $(this).removeAttr('required')
      }
    });
    check_to_hide_or_show_remove_link();
    set_web_desktop_app_product_input_fields();
    product_toggle_fields('show')
    show_expiry_feilds();
  }
  else if(product_type === 'Publication') {
    publication_section.show();
    $('#product_attachment').attr('required', 'required');

    site_link.hide();
    url_element.removeAttr('required')
    url_element.removeAttr('data-parsley-urlstrict');

    versions_section.hide();
    $('.nested-fields input').removeAttr('required')
    product_toggle_fields('show')
    on_boarding_price_element.hide();
    $('#product_on_boarding_price').removeAttr('required');
    renewal_price_label('Original Price');
    hide_expiry_feilds();
  }

  else if(product_type === 'Membership') {
    publication_section.hide();
    $('#product_attachment').removeAttr('required');

    site_link.hide();
    url_element.removeAttr('required')
    url_element.removeAttr('data-parsley-urlstrict');

    versions_section.hide();
    $('.nested-fields input').removeAttr('required')
    product_toggle_fields('hide')
    set_web_desktop_app_product_input_fields();
    renewal_price_label();
    show_expiry_feilds();
  }

  else {
    versions_section.hide();
    site_link.hide();
    url_element.removeAttr('required')
    url_element.removeAttr('data-parsley-urlstrict');
    $('.nested-fields input').removeAttr('required')
    $('.nested-fields .versions input').removeAttr('required')
    $('.nested-fields .attachments input').removeAttr('required')
    $('#product_on_boarding_price').removeAttr('required');
    on_boarding_price_element.hide()
    renewal_price_label('Original Price');
    publication_section.hide();
    $('#product_attachment').removeAttr('required')
    product_toggle_fields('show')
    on_boarding_price_element.hide();
    hide_expiry_feilds();
  }
}

function product_toggle_fields(option) {
  discount_price = $('#discount-price')
  discount_price_qty = $('#discount-price-qty')
  sales_info_section = $('#sales-info')
  product_image = $('#product-img')
  content_types = $('#content-types')

  if(option === 'hide') {
    discount_price.hide()
    discount_price_qty.hide()
    sales_info_section.hide()
    product_image.hide()
    content_types.hide()
  }
  else if(option == 'show') {
    discount_price.show()
    discount_price_qty.show()
    sales_info_section.show()
    product_image.show()
    content_types.show()
  }
}
function hide_expiry_feilds() {
  $('#product_on_boarding_expiry').removeAttr('required');
  $('#product-on-boarding-expiry').hide();
  $('#product_renewal_expiry').removeAttr('required')
  $('#product-renewal-expiry').hide();
}

function show_expiry_feilds() {
  $('#product-on-boarding-expiry').show();
  $('#product-renewal-expiry').show();
  $('#product_on_boarding_expiry').attr('required', 'required');
  $('#product_renewal_expiry').attr('required', 'required');
}

function set_default_product_view() {
  if(localStorage.getItem('product-view') == undefined)
  {
    localStorage.setItem("product-view", 'grid');
  }
  update_product_view();
}

function update_product_view() {
  grid_btn = $('.grid-view');
  list_btn = $('.list-view');
  grid_section = $('.all-products > .grid');
  list_section = $('.all-products > .list');

  if(localStorage.getItem('product-view') == 'grid')
  {
    grid_section.show();
    grid_btn.addClass('active');

    list_section.hide();
    list_btn.removeClass('active');
  }
  else
  {
   list_section.show();
   list_btn.addClass('active');

    grid_section.hide();
    grid_btn.removeClass('active');
  }
}

function adjust_header() {
  header = document.getElementById('client-custom-navbar');
  title_bar = document.getElementById('client-page-title-bar');
  nav_bar_brand = document.getElementById('client-side-logo');

  if(header && nav_bar_brand)
  {
    if (window.pageYOffset > 45) {
      header.classList.add('sticky-top-header')
      header.style.position = 'fixed';
      header.style.top = '0';
      header.style.width = '100%';
      header.style.height = '65px';
      header.style.zIndex = '100';
      if(title_bar)
        title_bar.style.marginTop = '65px';

      nav_bar_brand.style.width = '35px';
      nav_bar_brand.style.height = '45px';
      nav_bar_brand.style.backgroundSize = '35px';
      $('#nav-cart-sign').removeClass('d-none');
    }
    else {
      header.classList.remove('sticky-top-header')
      header.style.position = '';
      header.style.top = '';
      header.style.height = '105px';
      if(title_bar)
        title_bar.style.marginTop = '0';

      nav_bar_brand.style.width = '194px';
      nav_bar_brand.style.height = '43px';
      nav_bar_brand.style.backgroundSize = '';
      $('#nav-cart-sign').addClass('d-none');
    }
  }
}

function pad_integer(number) {
  return String(number).padStart(2, '0')
}


function validate_credit_card_form() {
  format_card_field();

  $('.payment-grid').on('submit', '.credit-card-form', function(event) {
    if($("input[name='user[payment_method]']:checked").val() != 'credit_card' || $('.payment-method-radio-btn').data('cardExits') == true) {
      $('#app-spinner').removeClass('d-none');
      return
    }

    card_form_selector = $('.credit-card-form')
    credit_card_number_selector = $('#cc-num')
    ccnum_feedback_selector = $('#cc-num-feedback')
    expiry_selector = $('#cc-expiry')
    expiry_feedback_selector = $('#cc-expiry-feedback')
    cvc_selector = $('#cc-cvc')
    cvc_feedback_selector = $('#cc-cvc-feedback')
    month_selector = $('#exp-month')
    year_selector = $('#exp-year')
    card_type_selector = $('#card-type')
    payform = $.payform

    cc_num_valid = true
    expiry_date_valid = true
    cvc_valid = true

    card_expiry = payform.parseCardExpiry(expiry_selector.val())
    card_type_selector.val(payform.parseCardType(credit_card_number_selector.val()))

    cc_num_valid = validate_card_field_length(credit_card_number_selector, ccnum_feedback_selector, 14, credit_card_number_selector.data('incompleteMsg'))
    cvc_valid = validate_card_field_length(cvc_selector, cvc_feedback_selector, 3, cvc_selector.data('incompleteMsg'))
    expiry_date_valid = validate_card_expiry(card_expiry, expiry_selector, expiry_feedback_selector, month_selector, year_selector)

    cc_num_valid = is_card_field_valid(cc_num_valid, payform.validateCardNumber(credit_card_number_selector.val()), credit_card_number_selector, ccnum_feedback_selector, credit_card_number_selector.data('invalidMsg'))
    expiry_date_valid = is_card_field_valid(expiry_date_valid, payform.validateCardExpiry(card_expiry.month, card_expiry.year), expiry_selector, expiry_feedback_selector, expiry_selector.data('invalidMsg'))
    cvc_valid = is_card_field_valid(cvc_valid, payform.validateCardCVC(cvc_selector.val(), payform.parseCardType(credit_card_number_selector.val())), cvc_selector, cvc_feedback_selector, cvc_selector.data('invalidMsg'))

    valid_form = cc_num_valid && expiry_date_valid && cvc_valid
    if(valid_form) {
      $('#app-spinner').removeClass('d-none');
      $('#create-card-btn').trigger('click');
    } else {
      return false;
    }
  });
}

function format_card_field() {
  $('#cc-num').payform('formatCardNumber');
  $('#cc-expiry').payform('formatCardExpiry');
  $('#cc-cvc').payform('formatCardCVC')
}

function validate_card_field_length(field_selector, field_feedback_selector, required_length, message) {
  value = field_selector.val().trim()
  is_valid = true

  if(value.length < required_length) {
    field_selector.addClass('is-invalid')
    field_feedback_selector.text(message)
    is_valid = false
  } else {
    field_selector.removeClass('is-invalid')
    field_feedback_selector.text('')
    is_valid = true
  }

  return is_valid
}

function is_card_field_valid(length_validation, format_validation, field_selector, field_feedback_selector, message) {
  is_valid = length_validation

  if(is_valid) {
    if(format_validation) {
      is_valid = true
      field_selector.addClass('is-valid')
      field_selector.removeClass('is-invalid')
      field_feedback_selector.text('')
    } else {
      is_valid = false
      field_selector.addClass('is-invalid')
      field_selector.removeClass('is-valid')
      field_feedback_selector.text(message)
    }
  }

  return is_valid
}

function validate_card_expiry(card_expiry, expiry_selector, expiry_feedback_selector, month_selector, year_selector) {
  if(!card_expiry.month || !card_expiry.year) {
    expiry_selector.addClass('is-invalid')
    expiry_feedback_selector.text(expiry_selector.data('incompleteMsg'))
    expiry_date_valid = false
  } else {
    expiry_selector.removeClass('is-invalid')
    expiry_feedback_selector.text('')
    expiry_date_valid = true

    month_selector.val(pad_integer(card_expiry.month))
    year_selector.val(card_expiry.year)
  }

  return expiry_date_valid
}

function set_active_category_filter() {
  if($('.category-filter li a.active').length == 0)
    $('.category-filter li a.all').addClass('active')
}

function clear_filter_option() {
  $('.clear-filter').hide();

  $('.search-filters').each(function() {
    if($(this).val() != '')
      $('.clear-filter').show();
  });
}

function clear_search_fields() {
  $('.clear-search').hide();

  $('.search-text-field').each(function() {
    if($(this).val() != '')
      $('.clear-search').show();
  });
}

function set_web_desktop_app_product_input_fields() {
  $('#product_on_boarding_price').attr('required', 'required');
  on_boarding_price_element.show();
  renewal_price_label()
  on_boarding_price_label()
  publication_section.hide();
  $('#product_attachment').removeAttr('required');

}

function renewal_price_label(label = 'Renewal Price' ) {
  $('#renewal-price-label').text(label);
}

function on_boarding_price_label() {
  $('#on-boarding-price-label').text('Onboarding Price');
}

function check_to_hide_or_show_remove_link() {
  remove_version = $('#product-versions .remove-version')
  visible_versions = $('#product-versions .product-version-fields:visible')

  if(visible_versions.length == 1) {
    remove_version.hide();
  } else {
    remove_version.show();
  }

  visible_versions.each(function () {
    if($(this).find('.product-attachment-fields:visible').length == 1) {
      $(this).find('.remove-file').hide()
    } else {
      $(this).find('.remove-file').show()
    }
  })
}

function check_to_add_or_remove_active_cart() {
  cart = $('.nav-cart')

  if(parseInt(cart.attr('data-items-count')) > 0) {
    cart.addClass('active-cart')
  }
  else {
    cart.removeClass('active-cart')
  }
}

function unmask_cvc_digits() {
  $('#cvc-digits').text('');
  input = document.getElementById('cc-cvc');
  if (input.inputmask)
    input.inputmask.remove()
}

function set_expiry_date() {
  inpputValue = $('#expiry-field').val().split(' ')[0].split('-')
  formatted = inpputValue[2] + '-' + inpputValue[0] + '-'+ inpputValue[1]
  var expiryDate = new Date(formatted)
  var dateToday = new Date()
  if((expiryDate < dateToday) || (inpputValue[2].length != 4) ) {
    var tdate = new Date();
    var dd = tdate.getDate(); //yields day
    var mm = tdate.getMonth(); //yields month
    var yyyy = tdate.getFullYear(); //yields year
    var currentDate= ( mm+1) + "-" + dd + "-" + yyyy;
    $('#expiry-field').val(currentDate);
  }
}

function set_expiry_datetime() {
  inpputValue = $('.custom-datetime').val().split(' ')[0].split('-')
  formatted = inpputValue[2] + '-' + inpputValue[0] + '-'+ inpputValue[1]
  var expiryDate = new Date(formatted)
  var dateToday = new Date()
  if((expiryDate < dateToday) || (inpputValue[2].length != 4) ) {
    var dd = dateToday.getDate(); //yields day
    var mm = dateToday.getMonth(); //yields month
    var yyyy = dateToday.getFullYear(); //yields year
    var mins = dateToday.getMinutes();
    var hours = dateToday.getHours();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    var currentDate= ( mm+1) + "-" + dd + "-" + yyyy + ' ' + hours + ':' + mins + ' ' + ampm;
    $('.custom-datetime').val(currentDate);
  }
}

function set_expiry_datetime_expiry() {
  inpputValue = $('.custom-datetime-expiry').val().split(' ')[0].split('-')
  formatted = inpputValue[2] + '-' + inpputValue[0] + '-'+ inpputValue[1]
  var expiryDate = new Date(formatted)
  var dateToday = new Date()
  if((expiryDate < dateToday) || (inpputValue[2].length != 4) ) {
    var dd = dateToday.getDate(); //yields day
    var mm = dateToday.getMonth(); //yields month
    var yyyy = dateToday.getFullYear(); //yields year
    var mins = dateToday.getMinutes();
    var hours = dateToday.getHours();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    var currentDate= ( mm+1) + "-" + dd + "-" + yyyy + ' ' + hours + ':' + mins + ' ' + ampm;
    $('.custom-datetime-expiry').val(currentDate);
  }
}