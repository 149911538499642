import Rails from "@rails/ujs";
window.Rails = Rails;
Rails.start();
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require('jquery')

require('cocoon')
require("@rails/actiontext")
require("moment/locale/en-au")
require("tempusdominus-bootstrap-4")

import 'inputmask';
import 'bootstrap';
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all.css";
import 'password-strength-meter';
import 'payform/dist/jquery.payform';
import 'chosen-js'
import 'custom/script';
import 'custom/bulk_selection';
import 'custom/sweet_alert';
import 'custom/parsley'
import 'custom/tooltip'
import 'custom/date_picker'
import 'custom/recaptcha'
import 'custom/users'
import 'custom/sortable'
import 'custom/fixed_columns_table'
import { tinyMce } from '../custom/tinyMce';
document.addEventListener('turbolinks:load', function () { tinyMce(); });
