import $ from 'jquery'
import 'bootstrap-datepicker';

$(document).on('turbolinks:load ready', function() {
  bind_date_picker()
  let start_date_element = $('.linked-picker-start-date')
  let end_date_element = $('.linked-picker-end-date')

  $('.schedule-dates').toggle();
  if($('#product_sales_price').val() != '') {
    $('.schedule-dates').toggle();
    $('#schedule').text('Cancel');
  }

  $('#schedule').on('click', function () {
    $('.schedule-dates').toggle();
    if($(this).text() == 'Schedule')
    {
      $(this).text('Cancel');
    }
    else {
      $(this).text('Schedule');
      $('.schedule-dates').datepicker('update', '');
      $('.datepicker-inline').remove();
      end_date_element.datepicker('setStartDate', '');
      start_date_element.datepicker('setEndDate', '');
    }
  });

  start_date_element.on('changeDate', function(e) {
    $(this).parsley().validate();
    end_date_element.datepicker('setStartDate', e.date)
  })

  end_date_element.on('changeDate', function(e) {
    $(this).parsley().validate();
    start_date_element.datepicker('setEndDate', e.date)
  })

  $('.month-picker').datepicker({
    format: 'yyyy-mm-dd',
    viewMode: "months",
    minViewMode: "months",
    autoclose: true
  });
});

function bind_date_picker () {
  $('.date-picker').datepicker({
    format: 'mm-dd-yyyy',
    autoclose: true,
  });
}
