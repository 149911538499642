import $ from 'jquery'
import 'custom/jquery.actual'
import 'bootstrap-table/dist/bootstrap-table'
import 'bootstrap-table/dist/extensions/sticky-header/bootstrap-table-sticky-header'
import 'bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns'

$(document).on('turbolinks:load ready', function() {
  subscription_tables();
})

window.subscription_tables = () => {
  $('.scrollable-table').each(function () {
    let table = $(this)
    let table_actual_height = table.actual('height');
    let height = Math.min(table_actual_height, 700)

    if(height == 700) {
      table.bootstrapTable({
        height: 700,
        fixedColumns: true,
        fixedNumber: table.data('left-columns'),
      })

      table.parents().eq(1).children().eq(1).height('655px')
    }
    else {
      table.bootstrapTable({
        height: table.height,
        fixedColumns: true,
        fixedNumber: table.data('left-columns'),
      })
      table.parents().eq(1).children().eq(1).height(table.height + 100 + 'px')
    }
  })
}
