import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap.bundle';

$(document).on('turbolinks:load ready', function() {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover({
    trigger: 'focus'
  });

  $('#admin-wrapper').on('click', '#close-modal', function(e) {
    e.preventDefault();

    $('.modal').modal('hide');
  });

  $('#admin-wrapper').on('click', '#refresh-tooltip', function(e) {
    e.preventDefault();

    $('.close-its-tooltip').tooltip('hide');
    $('[data-toggle="tooltip"]').tooltip();
  });

  $(document).on('click', '.add-file', function() {
    $('.add-file').tooltip('hide');
    $('.remove-file').tooltip();
  });

  $('#client-wrapper').on('click', '.autorenew-toggle', function() {
    let url = `${$(this).data('requestPath')}?product_id=${$(this).data('productId')}&expiry=${$(this).data('expiry')}&payment_status=${$(this).data('paymentStatus')}`

    $.ajax({
      type: "GET",
      url: url
    });
  });

  $('#dashboard-wrapper').on('click', '#close-modal', function(e) {
    e.preventDefault();

    $('.modal').modal('hide');
  });

  $('#dashboard-wrapper').on('click', '#open-modal', function(e) {
    e.preventDefault();

    $('.modal').modal('show');
  });

  if($('#alerts-modal').length && $('#alerts-modal').data('display-alerts')) {
    $('#alerts-modal').modal('show');
  }

  $('.dropdown-toggle-button').on('click', function(e) {
    e.preventDefault();
    hide_tooltip()
  })
  $('.custom-wrapper').on('click', '.notifications-close-btn', function(e) {
    e.preventDefault();

    hide_tooltip()
  })

  $('.custom-wrapper').on('mouseover', '.dropdown-menu', function(e) {
    e.preventDefault();
    hide_tooltip()
  })

  window.openModal = function(id) {
    $('#' + id).modal('show')
  }

  $(document).on('click', '.manual-renew', function() {
    $('#dynamicModal').modal('hide')
  })
});

function hide_tooltip() {
  $('[data-toggle="tooltip"]').tooltip('hide');
  $('[data-toggle="tooltip"]').tooltip();
}
