window.loginWigdet = 0
window.SignUpWidget = 1
window.lostWigdet = 2
window.ResetWidget = 3

$(document).on('turbolinks:load ready', function() {
  window.submitInvisibleRecaptchaLoginForm = function(){
    document.getElementById("new_signin_user").submit();
  }

  window.submitInvisibleRecaptchaSignUpForm = function() {
    form = document.getElementById("new_signup_user");
    Rails.fire(form, 'submit')
  }

  window.submitInvisibleRecaptchaForgotPasswordForm = function () {
    document.getElementById("forgot-password-form").submit();
  }

  window.submitInvisibleRecaptchaResetPasswordForm = function () {
    document.getElementById("reset-password-form").submit();
  }

  window.captchaErrorCallback = function () {
    if(document.getElementById('login-captcha-btn') != null || document.getElementById('sign-up-captcha-btn') != null || document.getElementById('forgot-password-captcha') != null || document.getElementById('reset-password-captcha') != null) {
      alert('There seems to be an Internet connection issue. Refreshing page.')
      window.location.reload()
    }
  }

  window.onloadCallback = function() {
    site_key = $('.custom-wrapper').data('site-key')
    if(document.getElementById('login-captcha-btn') != null) {
      loginWigdet = grecaptcha.render('login-captcha-btn', {
        'sitekey' : site_key,
        'size' : 'invisible',
        'callback' :'submitInvisibleRecaptchaLoginForm',
        'error-callback' : 'captchaErrorCallback'
      });
    }

    if(document.getElementById('sign-up-captcha-btn') != null) {
      SignUpWidget = grecaptcha.render(document.getElementById('sign-up-captcha-btn'), {
        'sitekey' : site_key,
        'size' : 'invisible',
        'callback': 'submitInvisibleRecaptchaSignUpForm',
      });
    }

    if(document.getElementById('forgot-password-captcha') != null) {
      lostWigdet = grecaptcha.render('forgot-password-captcha', {
        'sitekey' : site_key,
        'size' : 'invisible',
        'callback' : 'submitInvisibleRecaptchaForgotPasswordForm',
        'error-callback' : 'captchaErrorCallback'
      });
    }

    if(document.getElementById('reset-password-captcha') != null) {
      ResetWidget = grecaptcha.render(document.getElementById('reset-password-captcha'), {
        'sitekey' : site_key,
        'size' : 'invisible',
        'callback': 'submitInvisibleRecaptchaResetPasswordForm',
        'error-callback' : 'captchaErrorCallback'
      });
    }
  };

  $('.login-btn').on('click', function(event) {
    form = document.getElementById("new_signin_user")

    if(Parsley.$element.find(form).parsley().isValid()) {
      event.preventDefault();
      grecaptcha.execute(loginWigdet);
    }
  })

  $('.register-btn').on('click', function(event) {
    form = document.getElementById("new_signup_user")

    if(Parsley.$element.find(form).parsley().isValid()) {
      event.preventDefault();
      grecaptcha.execute(SignUpWidget);
    }
  });

  $('.forgot-password-btn').on('click', function(event) {
    form = document.getElementById("forgot-password-form")

    if(Parsley.$element.find(form).parsley().isValid()) {
      event.preventDefault();
      grecaptcha.execute(lostWigdet);
    }
  });

  $('.change-password').on('click', function(event) {
    form = document.getElementById("reset-password-form")

    if(Parsley.$element.find(form).parsley().isValid()) {
      event.preventDefault();
      grecaptcha.execute(ResetWidget);
    }
  })

  window.addEventListener('online', function() {
    captchaErrorCallback()
  });

  window.addEventListener('offline', function() {
    captchaErrorCallback()
  });
})
